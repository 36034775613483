.Emprendimiento {
  .data {
    padding: 8vh 0 10vh 0;
    .overflow-hidden {
      transition: 0.3s ease all;
      // overflow: hidden;
      position: relative;
      .icon-share {
        z-index: 99;
        position: relative;
        // transform: skew(350deg) !important;
      }
      .social-block {
        overflow: hidden;
        position: relative;
        transition: 0.3s ease all;
        a {
          width: 2.5rem;
          height: 2.5rem;
          margin-right: 2rem !important;
        }
        &.hidden {
          transition: 0.3s ease all;
          transform: translateX(-1000px);
          left: 0;
          opacity: 0;
          z-index: -1;
          position: absolute;
          margin-right: 3rem !important;
        }
      }
    }
    .overflow-visible {
      .social-block {
        margin-right: 1rem;
        a {
          margin-right: 0.5rem;
        }
      }
    }
    .type {
      font-size: 2.3rem;
      color: $bg-primary;
      font-weight: 400;
    }
    .address {
      font-size: 5rem;
      color: $secondary-color;
      margin: 0rem 0 3rem 0;
    }
    .location {
      font-size: 3rem;
    }
    p {
      font-size: 1.5rem;
      font-weight: 400;
      line-height: 1.25;
      width: 90%;
      color: #0f0f0f;
    }
    .line {
      margin-top: 6vh;
      display: block;
      width: 100%;
      height: 1rem;
      background: $tertiary-color;
    }
    .btn {
      width: 3rem;
      height: 3rem;
      // border: 2px solid;
      transform: skew(350deg) !important;
      &::before {
        transform: rotate(355deg);
      }
    }
    .item {
      font-weight: 400;
      .tick {
        // background: $tertiary-color;
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 100%;
        i {
          color: $bg-primary;
          font-size: 1rem;
        }
      }
    }
    .both {
      width: 19rem;

      h5 {
        font-size: 1.6em;
        font-weight: 430;
        // margin-bottom: 2px;
      }
      span {
        font-size: 1.6rem;
        font-weight: 430;
      }
    }
    .map {
      height: 60vh;
      object-fit: cover;
      object-position: center;
      margin-top: 6rem;
    }
  }
}

@media (max-width: 992px) {
  .Emprendimiento {
    .data {
      padding: 8vh 0 15vh 0;
      .type {
        font-size: 1rem;
      }
      .address {
        margin: 2rem 0;
        font-size: 2.3rem;
        .location {
          font-size: 1.5rem;
        }
      }
      .btn {
        margin-bottom: 2rem;
      }
      p {
        width: 100%;
        font-size: 1.1rem;
        margin-bottom: 2rem;
      }
      .both {
        h5,
        span {
          font-size: 1.3rem;
        }
      }
    }
  }
}
