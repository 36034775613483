.Inicio {
  font-family: "FR Hopper 430";
  .tasaciones {
    padding-top: 11vh;
    padding-bottom: 11vh;
    background-color: #efefef;
    h2 {
      font-size: 4.6rem;
      margin-top: 2.5rem;
      color: $secondary-color;
      line-height: 1.2;
      font-weight: 300;
      .red-color {
        color: $bg-primary;
      }
    }
    .small-text {
      font-size: 1.9rem;
      color: #1d1d1d;
    }
    .featured__content {
      // overflow: hidden;
      // // display: none;
      // justify-content: end;
      // width: 50%;
      // height: 50%;
      // transition: all 1s ease;
      overflow: hidden;
      justify-content: end;
      width: 20%;
      height: 20%;
      right: 0;
      bottom: 0;
      position: absolute;
      // background: transparent linear-gradient(123deg, #ed372dd2 0%, #cc3830ce 47%, #951612c7 100%) 0% 0% no-repeat padding-box;
      transform: none !important;
      z-index: -1;
    }
    .featured {
      margin-bottom: 2rem;
      overflow: hidden;
      &::before {
        content: "";
        z-index: 2;
        background-image: url("../../images/look-icon.png");
        background-repeat: no-repeat;
        background-position: right bottom;
        background-size: cover;
        height: 151px;
        width: 171px;
        bottom: 0;
        display: block;
        position: absolute;
        right: 0;
        transition: 1s all ease;
      }
      &::after {
        content: "";
        height: 20rem;
        width: 20rem;
        background: transparent
          linear-gradient(123deg, #ed372dd2 0%, #cc3830ce 47%, #951612c7 100%)
          0% 0% no-repeat padding-box;
        z-index: 4;
        position: absolute;
        right: -12rem;
        transform: rotate(45deg);
        transition: 1s all ease;
        bottom: -12rem;
        opacity: 0;
      }
      &:hover {
        &::before {
          // transition: 1s ease-out;
          display: none;
        }
        &::after {
          opacity: 1;
          height: 100rem;
          width: 165rem;
          transform: translate(-15rem, -15rem), rotate(45deg);
          // bottom: -400px;
        }
        .featured__content {
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          width: 100%;
          height: 100%;
          transition: 0s linear;
          z-index: 5;
          // background: transparent linear-gradient(123deg, #ed372dd2 0%, #cc3830ce 47%, #951612c7 100%) 0% 0% no-repeat padding-box;
          // transform: none !important;
          &__title {
            color: #fff;
          }
          &__location {
            color: #fff;
            i {
              margin-right: 1rem;
            }
          }
          &__btn {
            background: white;
            border: none;
            color: black;
            padding: 1.5rem !important;
            transform: none !important;
            width: 60px;
            height: 60px;
            font-size: 1.6rem;
            &:hover {
              background: $bg-primary;
              color: #fff;
            }
          }
        }
      }
    }
    .prop-dest-1,
    .prop-dest-2 {
      background-image: url("../../images/pd-1.jpg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      width: 95%;
      height: 450px;
      box-shadow: 0px 0px 30px #00000040;
    }
    .prop-dest-2 {
      background-image: url("../../images/pd-2.jpg");
    }
    .btn {
      padding: 1.5rem 2rem 1.5rem 5rem;
      font-size: 1.7rem;
      //   transform: skew(0deg);
      text-align: center;
      //   position: relative;
      overflow: visible;
      border: none !important;
      //   transition: 0.35s linear;
    }
    .btn.btn-effect::after {
      content: "";
      background: none;
      width: 100%;
      border: 2px solid $bg-primary;
      transform: skew(343deg);
      position: absolute;
      z-index: 1;
    }
    .btn.btn-effect::before {
      content: "";
      position: absolute;
      left: 0;
      width: 83px;
      background: #ed362d;
      transform: skew(343deg);
      left: -29px;
      z-index: 2;
    }
  }
  .div_button_mas_tasaciones {
    .btn {
      height: 80px;
      width: 225px;
      font-size: 1.7rem;
      position: relative;
      //   display: block;
      text-align: center;
      border: none !important;
      z-index: 0;
      overflow: visible;
      p {
        color: $bg-secondary;
        font-family: "Lato-Regular" !important;
        font-size: 1.5rem;
        position: absolute;
        left: 75px;
        top: 21px;
        z-index: 4;
      }
      &:focus {
        outline: none;
        box-shadow: none;
        border: none;
      }
      &:hover {
        p {
          color: white;
        }
        .background_buttom_ver_mas {
          //   display: block;
          &::after {
            width: 91%;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            -webkit-transition: all 0.35s;
            transition: all 0.35s;
          }
        }
        span {
          -webkit-transition: all 0.35s;
          transition: all 0.35s;
          &:nth-child(1) {
            transform: rotate(180deg) translate(0px, -9px);
            // left: 10px;
          }
          &:nth-child(2) {
            transform: rotate(450deg) translate(6px, 0px);
          }
        }
      }
      span {
        margin: auto;
        width: 1.7rem;
        height: 0.25rem;
        background-color: #ffffff;
        display: block;
        position: absolute;
        left: 0px;
        z-index: 6;
        -webkit-transition: all 0.5s;
        transition: all 0.5s;
        &:nth-child(1) {
          transform: rotate(90deg) translate(9px, 0px);
          top: 27px;
        }
        &:nth-child(2) {
          transform: rotate(360deg) translate(0px, 6px);
          top: 30px;
        }
      }
      .background_buttom_ver_mas {
        // display: none;
        &::after {
          content: "";
          background: $bg-primary;
          width: 35%;
          border: 1px solid $bg-primary;
          transform: skew(343deg);
          position: absolute;
          z-index: 1;
          top: 0px;
          left: -13%;
          height: 75px;
          -webkit-transition: all 1s;
          transition: all 1s;
        }
      }
      &::after {
        content: "";
        background: none;
        width: 70%;
        border: 1px solid $bg-primary;
        transform: skew(343deg);
        position: absolute;
        z-index: 5;
        top: 0px;
        left: 47px;
        height: 75px;
      }
      &::before {
        content: "";
        position: absolute;
        left: 0;
        width: 78px;
        background: $bg-primary;
        transform: skew(343deg);
        left: -29px;
        height: 75px;
        top: 0px;
        z-index: 5;
      }
    }
  }
}

@media (max-width: 1600px) {
  .Inicio {
    .tasaciones {
      h2,
      .red-color {
        font-size: 4rem;
      }
    }
  }
}

@media (max-width: 1200px) {
  .Inicio {
    .tasaciones {
      h2,
      .red-color {
        font-size: 3.5rem;
      }
    }
  }
}

@media (max-width: 992px) {
  .Inicio {
    .tasaciones {
      text-align: center;
      padding-top: 5vh;
      padding-bottom: 10vh;
      .featured {
        &::after {
          opacity: 0.8;
          height: 100rem;
          width: 165rem;
        }
        .featured__content {
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          width: 100%;
          height: 100%;
          transition: 0s linear;
          z-index: 5;
          color: #fff;
        }
        .featured__content__btn {
          background: white;
          border: none;
          color: black;
          padding: 1.5rem !important;
          transform: none !important;
          width: 60px;
          height: 60px;
          font-size: 1.6rem;
        }
      }
      span {
        font-weight: 300;
      }
      h2 {
        margin: 2rem 0 2.5rem 0;
        font-size: 2rem;
        line-height: 1.1;
        .red-color {
          font-size: 2rem;
        }
      }
      .btn {
        margin-top: 3rem;
        display: block;
      }
      #sub {
        display: none;
      }
    }
  }
}
