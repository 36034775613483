.nosotros {
    .main {
        // overflow: hidden;
        min-height: 100vh;
        min-width: 100%;
        position: relative;
        background-color: transparent;
        &::before {
            content: "";
            height: 100 vh;
            z-index: 0;
            position: absolute;
            left: -24rem;
            right: 0;
            bottom: 0;
            top: 0;
            width: 56% !important;
            background: transparent linear-gradient(90deg, #646464 0%, #323232 100%) 0% 0% no-repeat padding-box;
            box-shadow: 0px 5px 15px #00000033 !important;
            transform: skew(8deg) !important;
        }
        .corte_diagonal {
            padding-bottom: 7.5vh;
        }
        .row {
            padding-top: 15.25rem;
            .brad_crumbs_nosotros {
                div {
                    nav {
                        ol {
                            //   justify-content: center;
                            a {
                                color: white !important;
                            }
                            li {
                                &::before {
                                    color: white;
                                }
                            }
                        }
                    }
                }
            }
            .corte_diagonal {
                .text_acerca {
                    // font-size: 2rem !important;
                    text-transform: uppercase;
                    color: $bg-quarter;
                }
                .title_nosotros {
                    font-size: 3.5rem;
                    color: #fff;
                    font-weight: 200;
                    // margin-top: 5vh;
                    margin-bottom: 15vh;
                }
                .content-logo {
                    position: relative;
                    .logo-absolute {
                        // width: 20vh;
                        top: -10rem;
                        right: 0vw;
                        position: absolute;
                        // height: 22vh;
                        z-index: 3;
                    }
                }
                .rotating {
                    -webkit-animation: rotating 20s linear infinite;
                    -moz-animation: rotating 20s linear infinite;
                    -ms-animation: rotating 20s linear infinite;
                    -o-animation: rotating 20s linear infinite;
                    animation: rotating 20s linear infinite;
                }
            }
            .h-100 {
                height: 100vh;
                .content-main {
                    padding: 5.25rem 5rem;
                    padding-top: 0;
                    .div_body_nosotros {
                        margin-top: 0;
                        .title {
                            h3 {
                                color: $bg-primary;
                            }
                        }
                        .text {
                            p {
                                font-size: 1rem;
                                font-weight: 300;
                                // width: 80%;
                                line-height: 1.2;
                                margin-top: 4rem;
                                color: $bg-secondary;
                                text-align: left;
                                letter-spacing: 1px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 1920px) {
    .nosotros {
        .main {
            .row {
                .text {
                    p {
                        font-size: 0.8rem !important;
                    }
                }
            }
        }
    }
}

@media (max-width: 1700px) {
    .nosotros {
        .main {
            &::before {
                left: -24rem;
                width: 57% !important;
                background: transparent linear-gradient(90deg, #646464 0%, #323232 100%) 0% 0% no-repeat padding-box;
                box-shadow: 0px 5px 15px #00000033;
                transform: skew(8deg) !important;
            }
            .row {
                .text {
                    p {
                        font-size: 1.05rem !important;
                    }
                }
            }
        }
    }
}

@media (max-width: 1500px) {
    .nosotros {
        .main {
            &::before {
                left: -24rem;
                width: 57% !important;
                background: transparent linear-gradient(90deg, #646464 0%, #323232 100%) 0% 0% no-repeat padding-box;
                box-shadow: 0px 5px 15px #00000033;
                transform: skew(8deg) !important;
            }
        }
    }
}

@media (max-width: 1300px) {
    .nosotros {
        .main {
            &::before {
                left: -24rem;
                width: 66% !important;
                background: transparent linear-gradient(90deg, #646464 0%, #323232 100%) 0% 0% no-repeat padding-box;
                box-shadow: 0px 5px 15px #00000033;
                transform: skew(5deg) !important;
            }
        }
    }
}

@media (min-width: 992px) {
    .nosotros {
        .main {
            .full-fluid {
                max-width: 95%;
                margin-left: auto;
                padding-right: 0;
                margin-right: 0;
                position: relative;
            }
        }
    }
}

@media (max-width: 992px) {
    .nosotros {
        .main {
            background: linear-gradient(90deg, white 0%, #ebebeb 100%);
            min-height: unset;
            &::before {
                opacity: 0.9;
                left: 0rem;
                width: 100% !important;
                background: transparent linear-gradient(90deg, #646464 0%, #323232 100%) 0% 0% no-repeat padding-box;
                box-shadow: 0px 5px 15px #00000033;
                transform: skew(360deg) !important;
            }
            .row {
                padding-top: 10rem;
                .corte_diagonal {
                    padding-bottom: 3rem;
                    .content-logo {
                        display: none !important;
                    }
                    .title_nosotros {
                        margin-bottom: 0;
                    }
                }
                text-align: center;
                height: unset;
                position: relative;
                .h-100 {
                    // height: 100vh !important;
                    h2 {
                        font-size: 1.5rem;
                        margin-bottom: 10vh;
                        margin-top: 0;
                    }
                    h5 {
                        font-size: 1rem;
                        font-weight: 400;
                        color: #ffff;
                        line-height: 1.6;
                        // letter-spacing: px;
                        br {
                            margin-bottom: 0.5rem;
                            display: block;
                        }
                        span {
                            width: 10rem;
                            background-color: #fff;
                            height: 1px;
                            vertical-align: middle;
                        }
                    }
                    img {
                        width: 100%;
                        height: 100vh;
                        position: absolute;
                        left: 0;
                        top: 0;
                        object-fit: cover;
                        object-position: center;
                        bottom: 0;
                        right: 0;
                        z-index: -1;
                    }
                    .content-main {
                        padding: 3rem 0rem;
                        background: linear-gradient( 90deg, rgba(255, 255, 255, 1) 0%, rgba(235, 235, 235, 1) 100%);
                        &.h-100 {
                            height: unset;
                        }
                        .div_body_nosotros {
                            margin-top: 0rem;
                        }
                        span {
                            margin-bottom: 1rem;
                            display: block;
                            font-size: 1.3rem;
                        }
                        p {
                            font-size: 1rem;
                            line-height: 1.3;
                            width: 90%;
                            margin-left: auto;
                            margin-top: 3rem !important;
                            margin-right: auto;
                            br.d-none {
                                display: block !important;
                            }
                        }
                    }
                }
                .h-sm-auto {
                    height: unset !important;
                }
            }
        }
    }
}