.Resultados {
    .filter {
        padding: 1.85rem 2.12rem;
        // border-radius: 10px;
        max-width: 85%;
        // background-color: #efefef;
        // border: 1px solid #707070;
        box-shadow: 0px 0px 15px #00000033;
        &_header {
            h3 {
                font-size: 3rem;
                color: $secondary-color;
                font-weight: 430;
                margin-bottom: 2rem;
                letter-spacing: -1%;
                background-color: transparent;
            }
            button {
                border: none;
                font-size: 0.9rem;
                color: $primary-color;
                font-weight: 430;
                background-color: #ffffff;
            }
            .current-tags {
                .tag {
                    border: 2px solid $bg-primary;
                    //   border-radius: 10px;
                    padding: 0.5rem 0.5rem 0.5rem 1rem;
                    text-transform: uppercase;
                    margin-right: 0.5rem;
                    margin-bottom: 0.5rem;
                    font-size: 0.9rem;
                    font-weight: 340;
                    background-color: transparent;
                    color: $bg-primary;
                    //   border: 1px solid #5b5b5b;
                    .btn-delete-tag {
                        margin-left: 1rem;
                        height: 1.4rem;
                        cursor: pointer;
                        width: 1.4rem;
                        position: relative;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        transform: rotate(-45deg);
                        border-radius: 100%;
                        background-color: $bg-primary;
                        &::before {
                            content: "";
                            height: 2px;
                            width: 12px;
                            position: absolute;
                            display: block;
                            background-color: #ffffff;
                            transform: rotate(90deg);
                        }
                        &::after {
                            content: "";
                            height: 2px;
                            width: 12px;
                            position: absolute;
                            display: block;
                            background-color: #ffffff;
                            transform: rotate(180deg);
                        }
                    }
                }
            }
        }
        &_body {
            .filters-group {
                border-top: 1px solid #b1b1b1;
                h3 {
                    font-size: 1.5rem;
                    color: #5b5b5b;
                    font-weight: 300;
                    margin-bottom: 0;
                }
                .collapse-btn {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    cursor: pointer;
                    span {
                        border: none !important;
                        transform: rotate(270deg);
                        font-size: 2rem;
                        color: $bg-primary;
                        padding: 7px;
                        font-weight: 500;
                        line-height: 1;
                        &:before {
                            text-transform: none !important;
                        }
                        &:after {
                            border: none !important;
                            top: 10px;
                            left: 6.5px;
                            transition: 0.3s;
                        }
                    }
                    &.collapsed {
                        span {
                            transform: rotate(90deg);
                            transform-origin: center;
                        }
                    }
                }
                input[type="text"] {
                    border: 1px solid #b1b1b1;
                    border-radius: 10px;
                    padding: 0.8rem 1.7rem;
                    background-color: transparent;
                    line-height: 1;
                    font-size: 1rem;
                    width: 80%;
                    &::placeholder {
                        color: #b1b1b1;
                    }
                }
                .options {
                    .content-collapse {
                        padding: 1.3rem 0 0.8rem 0;
                        .text-danger {
                            font-weight: 400;
                        }
                        &.non-slider {
                            .noUi-target {
                                opacity: 0.8;
                                // // cursor: not-allowed
                            }
                        }
                        .noUi-target {
                            background: #707070;
                            // margin: 2rem 0;
                        }
                        .noUi-horizontal {
                            height: 4px;
                        }
                        .noUi-connect {
                            background: $bg-primary;
                            height: 4px;
                        }
                        .noUi-connects {
                            background-color: #707070;
                        }
                        .noUi-horizontal {
                            .noUi-handle {
                                width: 19px;
                                border-radius: 50%;
                                height: 19px;
                                top: -9px;
                                background: $bg-primary;
                                border: none;
                                outline: none !important;
                                box-shadow: none;
                                &:after {
                                    display: none;
                                }
                                &:before {
                                    height: 9px;
                                    width: 9px;
                                    background: $bg-primary;
                                    left: 5px;
                                    top: 5px;
                                    border-radius: 50%;
                                }
                                &.noUi-handle-upper {
                                    right: -3px;
                                }
                                &.noUi-handle-lower {
                                    right: -17px;
                                }
                            }
                            .noUi-tooltip {
                                background: transparent;
                                border: none;
                                top: 120%;
                                font-size: 0.8rem;
                            }
                        }
                        .option {
                            align-items: center;
                            display: flex;
                            margin-bottom: 0.67rem;
                            font-size: 1.1rem;
                            color: #929292;
                            input {
                                display: none;
                                &:checked+span {
                                    background: $bg-primary;
                                    // border-radius: 50%;
                                    border: 0;
                                    &:before {
                                        background: $bg-primary;
                                        transform: translate(-50%, -50%) scale(1);
                                    }
                                }
                            }
                            span {
                                display: inline-block;
                                border-radius: 50px;
                                vertical-align: middle;
                                position: relative;
                                margin-right: 0.9rem;
                                width: 22px;
                                height: 22px;
                                border: 1px solid #b1b1b1;
                                background: transparent;
                                &:before {
                                    content: "";
                                    display: inline-block;
                                    width: 10px;
                                    height: 10px;
                                    transition: 0.3s;
                                    position: absolute;
                                    top: 50%;
                                    left: 50%;
                                    transform: translate(-50%, -50%) scale(0);
                                }
                            }
                            .circle {
                                border-radius: 50%;
                                &::before {
                                    border-radius: 50%;
                                }
                            }
                        }
                    }
                }
                .square {
                    border-radius: 0 !important;
                }
            }
        }
    }
}

@media (max-width: 992px) {
    .Resultados {
        .width-auto {
            width: unset !important;
        }
        .close-filter {
            background-color: $bg-primary !important;
            color: #fff !important;
            span {
                width: 1.5rem;
                height: 2px;
                background-color: #000000;
                // margin-bottom: 5px;
                display: block;
                &:nth-child(1) {
                    transform: rotate(45deg);
                }
                &:nth-child(2) {
                    transform: rotate(-45deg) translate(1px, -1px);
                }
            }
        }
        .filter {
            position: fixed;
            top: -200%;
            left: 0;
            bottom: 0;
            right: 0;
            z-index: 9999999;
            max-width: 100%;
            border: none;
            border-radius: 0;
            overflow: scroll;
            height: 100vh;
            transition: 0.3s ease all;
            &.open {
                top: 0;
            }
            .filters-group {
                h3 {
                    font-size: 1.2rem;
                }
                .options .content-collapse .option {
                    font-size: 0.85rem;
                    letter-spacing: 0.7px;
                }
            }
        }
    }
}