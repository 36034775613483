.contacto {
  // background-color: $bg-primary;
  background: transparent
    linear-gradient(135deg, #ed362d 0%, #cc3730 47%, #951712 100%) 0% 0%
    no-repeat padding-box;
  &::before {
    content: "";
    position: absolute;
    width: 63%;
    left: -12rem;
    height: 100vh;
    background: transparent linear-gradient(133deg, #646464 0%, #323232 100%) 0%
      0% no-repeat padding-box;
    transform: skew(15deg, 0deg);
    z-index: 2;
  }
  .main {
    .row {
      padding-top: 10vh;
      height: 100vh;
      position: relative;
      .breadcrumb-item {
        color: rgba(255, 255, 255, 0.639);
        &::before {
          color: #fff;
        }
        a {
          color: #fff;
        }
      }
      .left-column {
        z-index: 3;
        .btn_consulta {
          border: 2px solid $bg-primary;
          color: #fff;
          width: fit-content;
          position: relative !important;
          &:focus {
            outline: none;
            box-shadow: none;
            border: none;
          }
          // left: 3.5rem;
          p {
            color: $bg-primary;
            z-index: 1;
            &::before {
              content: "";
              background: $bg-primary;
              z-index: 0;
              position: absolute;
              width: 4.5rem;
              top: 0;
              left: -2.8rem;
              height: 3.5rem;
              transform: skew(339deg, 360deg);
              transition: all 0.5s;
            }
          }
          &::before {
            // background: #fff;
            color: $bg-quarter;
            font-size: 1.3rem !important;
            font-weight: 430px !important;
            left: -2.5rem;
            z-index: 1;
            height: 3.5rem;
            transition: all 0.5s;
          }
          &::after {
            color: $primary-color;
            background: $bg-quarter;
            border: 1px solid $bg-primary;
            height: 3.5rem;
            z-index: -1;
          }
          &:hover {
            color: $primary-color;
            p {
              color: $bg-quarter;
              &::before {
                left: 17rem;
              }
            }
            &::before {
              left: 17rem;
            }
            &::after {
              background: $bg-primary;
            }
          }
        }
        span {
          color: #fff;
        }
        h2,
        .hover {
          color: #fff;
          font-size: 3.5rem;
          line-height: 1.2;
          font-weight: 200;
          margin-bottom: 5rem;
          transition: 0.3s ease all;
          text-decoration: none;
        }
        .hover {
          &:hover {
            color: $primary-color !important;
          }
        }
      }
      .img {
        position: absolute;
        flex: none !important;
        width: 75% !important;
        left: 25% !important;
        img {
          width: 100%;
          height: 100vh;
          opacity: 0.5;
          object-fit: cover;
          // position: absolute;
        }
      }
      .formulario {
        background: transparent;
        padding: 0 5vw;
        min-height: 100vh;
        color: $bg-quarter !important;
        input,
        textarea {
          &:first-child {
            margin-top: 3rem;
          }
          border: none;
          font-size: 2rem;
          padding-bottom: 1.1vh;
          color: $bg-quarter;
          font-weight: 300;
          display: block;
          margin-bottom: 3.5vh;
          border-bottom: 1px solid $bg-quarter;
          background: transparent;
          &::placeholder {
            color: $bg-quarter;
          }
        }
        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active {
          transition: background-color 5000s ease-in-out 0s;
          // -webkit-box-shadow: 0 0 0px 1000px unset;
          -webkit-text-fill-color: $bg-quarter;
        }
        .btn_consulta {
          // position: relative;
          height: 3.5rem;
          margin-top: 4rem !important;
          &:hover {
            p {
              color: $bg-secondary;
              &::before {
                left: 12.1rem;
                transition: all 0.5s;
              }
            }
            &::before {
              left: 12.1rem;
              transition: all 0.5s;
            }
            &::after {
              background: $bg-quarter;
            }
          }
          p {
            color: $bg-quarter;
            font-size: 1.5rem;
            &::before {
              content: "";
              position: absolute;
              background: $bg-quarter;
              height: 3.5rem;
              width: 3.5rem;
              transform: skew(340deg);
              left: -2rem;
              top: 0;
              transition: all 0.5s;
            }
          }
          &::before {
            font-family: "icomoon" !important;
            speak: never;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            content: "\e904";
            font-size: 2rem;
            color: $bg-secondary;
            left: -2rem;
            transition: all 0.5s;
          }
          &::after {
            content: "";
            font-size: 1rem;
            height: 3.5rem;
            border: 1px solid $bg-quarter;
          }
        }
      }
    }
  }
}

@media (max-width: 1700px) {
  .contacto {
    .main {
      .row {
        .left-column {
          span {
            font-size: 1rem;
          }
          h2,
          .hover {
            font-size: 3.1rem;
          }
          &.step-2-column {
            padding-top: 3vh;
          }
        }
        .formulario {
        }
      }
    }
  }
}

@media (max-width: 1300px) {
  .contacto {
    .main {
      .row {
        .left-column {
          span {
            font-size: 1rem;
          }
          h2,
          .hover {
            font-size: 2.3rem;
            margin-top: 1rem;
          }
          &.step-2-column {
            padding-top: 3vh;
          }
        }
        .formulario {
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .contacto {
    background: none !important;
    &::before {
      background: none !important;
    }
    .main {
      // min-height: 90vh;
      // overflow: hidden;
      position: relative;
      &::before {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        // background-color: $primary-color;
        background: $bg-secondary;
        opacity: 0.85;
        z-index: 9;
        content: "";
      }
      .full-fluid {
        min-height: 90vh;
      }
      .row {
        height: unset;
        .left-column {
          position: relative;
          z-index: 10;
          padding-top: 10vh;
          &.min-height-full {
            min-height: 90vh;
          }
          span {
            margin-bottom: 1rem;
            font-size: 1rem;
            text-transform: uppercase;
            letter-spacing: 2px;
          }
          h2,
          .hover {
            margin-bottom: 1.5rem;
            font-size: 1.8rem;
          }
          &.step-2-column {
            padding-top: 3vh;
          }
          .btn_consulta {
            margin-top: 0;
            font-size: 0.85rem;
            padding-top: 1.25rem;
            padding-left: 2.3rem;
            position: absolute !important;
            bottom: 10vh !important;
            left: 0.5rem;
            &::before {
              font-size: 1rem !important;
              top: 0px;
              height: 2.7rem;
            }
            &::after {
              left: 0;
              height: 3.2rem;
            }
            p {
              font-size: 0.75rem;
              &::before {
                top: 0 !important;
                height: 3.2rem;
              }
            }
            &:hover {
              &::before {
                left: 12rem;
              }
              p {
                &::before {
                  left: 11rem;
                  height: 3.2rem;
                }
              }
            }
            &::before {
              top: 0 !important;
            }
          }
        }
        .img {
          top: 0;
          img {
            height: 100vh;
            position: absolute;
            top: 0;
            // left: 0;
            right: 0;
            width: 100%;
            opacity: 1 !important;
            width: 100vh;
          }
        }
        .formulario {
          // margin-top: 10vh;
          min-height: unset;
          padding: 10vh 5vw;
          padding-top: calc(10vh + 4rem);
          z-index: 10;
          // background-color: transparent;
          input,
          textarea {
            font-size: 1.2rem;
          }
          .btn_consulta {
            position: relative;
            &:hover {
              p {
                &::before {
                  left: 12rem !important;
                }
              }
            }
          }
        }
      }
      .tick {
        width: 5rem;
        margin-bottom: 2rem;
        height: 5rem;
        i {
          color: #fff;
          font-size: 1.5rem;
        }
      }
      &.step-2 {
        max-height: 90vh;
        &::before {
          background-color: #fff !important;
          opacity: 0;
        }
        .full-fluid {
          .row {
            padding-top: 0vh;
            height: 100vh;
            min-height: 100vh;
            position: relative;
            h2 {
            }
            .text-thanks {
              font-size: 2rem;
              font-weight: 300;
            }
          }
        }
      }
      .btn {
        margin-top: 5vh;
        padding-top: 1rem;
        padding-bottom: 0.8rem;
        line-height: 1;
        &:hover {
          p {
            &::before {
              // left: 15rem !important;
              z-index: 1 !important;
            }
          }
        }
      }
    }
  }
}
