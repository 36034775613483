.Emprendimiento {
    .main {
        min-height: 100vh;
        background-attachment: fixed;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        div {
            position: absolute;
            bottom: 0px;
            right: 0rem;
            z-index: 9;
            a {
                background: none !important;
                width: 16.5rem !important;
                height: 16.5rem !important;
                border: 0 !important;
                &:hover {
                    border: none !important;
                }
                &::before {
                    content: "";
                    // z-index: 2;
                    background-image: url("../../images/look-icon.png");
                    background-repeat: no-repeat;
                    background-position: right bottom;
                    background-size: cover;
                    height: 14rem;
                    width: 16rem;
                    bottom: 0;
                    display: block;
                    position: absolute;
                    right: 0;
                    opacity: 0.85;
                }
                i {
                    display: none;
                }
            }
        }
        &::before {
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            content: "";
            // background-color: #0f0f0f;
            background: linear-gradient( 180deg, rgba(0, 0, 0, 0.751278) 10%, rgba(115, 115, 115, 0) 50%, rgba(0, 0, 0, 0.351278) 100%);
            background-attachment: fixed;
            background-position: center;
            background-size: cover;
            opacity: 1;
            z-index: 9;
            display: block;
        }
        .content-main {
            position: relative;
            height: 100vh;
            z-index: 9;
            h2 {
                font-size: 5rem;
                color: #fff;
                font-weight: 400;
            }
        }
    }
}

@media (max-width: 992px) {
    .Emprendimiento {
        .main {
            height: 90vh;
            background-attachment: unset;
            position: relative;
            min-height: 90vh;
            &::before {
                opacity: 0.6;
            }
            .content-main {
                height: 90vh;
                h2 {
                    font-size: 3rem;
                    display: block;
                    margin-bottom: 0 !important;
                    .location {
                        font-size: 1rem;
                    }
                }
                .btn {
                    width: 4rem;
                    height: 4rem;
                    font-size: 1rem;
                }
            }
            .btn {
                width: 4rem;
                height: 4rem;
                position: absolute !important;
                left: calc(0%);
                bottom: 5vh;
                font-size: 1rem;
            }
        }
    }
}