.propiedad {
    .main {
        background-position: center;
        // background-attachment: fixed;
        background-size: cover;
        background-repeat: no-repeat;
        min-height: 100vh;
        position: relative;
        &::before {
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            content: "";
            background-color: #0f0f0f;
            background: linear-gradient( 180deg, rgba(0, 0, 0, 0.751278) 10%, rgba(115, 115, 115, 0) 50%, rgba(0, 0, 0, 0.351278) 100%);
            //   background: linear-gradient(180deg, #ffffff 0%, #000000 100%) 0% 0%
            //     no-repeat padding-box;
            background-attachment: fixed;
            background-position: center;
            background-size: cover;
            opacity: 0.5;
            z-index: 2;
            display: block;
        }
        // &::after {
        //   content: "";
        //   width: 100%;
        //   position: absolute;
        //   bottom: 0;
        //   z-index: 2;
        //   height: 1.1rem;
        //   display: block;
        //   background-image: $tertiary-color;
        // }
        div {
            position: absolute;
            bottom: -1px;
            right: 0;
            z-index: 9;
            a {
                background: none !important;
                width: 23rem !important;
                height: 23rem !important;
                transition: 0;
                border: 0 !important;
                &::before {
                    content: "";
                    // z-index: 2;
                    background-image: url("../../images/look-icon.png");
                    background-repeat: no-repeat;
                    background-position: right bottom;
                    background-size: cover;
                    height: 20rem;
                    width: 22rem;
                    bottom: 0;
                    display: block;
                    position: absolute;
                    right: 0;
                    opacity: 0.85;
                }
                i {
                    display: none;
                }
                &:hover {
                    border: 0;
                    outline: 0;
                }
            }
        }
        .content-main {
            height: 100vh;
            color: #fff;
            padding-bottom: 4rem;
            z-index: 10;
            position: relative;
            h2 {
                font-size: 5rem;
                width: 90%;
            }
            .btn {
                padding: 0;
                font-size: 1.5rem;
                transform: none !important;
                border: none !important;
                transition: none;
                transform-style: none !important;
                img {
                    width: 100%;
                    opacity: 0;
                    height: 100%;
                }
            }
        }
    }
}

@media (max-width: 1600px) {
    .propiedad {
        .main {
            .content-main {
                div {
                    a {
                        width: 17.5rem !important;
                        height: 17.5rem !important;
                        &::before {
                            right: 17px !important;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 1300px) {
    .propiedad {
        .main {
            .content-main {
                div {
                    a {
                        width: 18.5rem !important;
                        height: 18.5rem !important;
                        &::before {
                            right: 32px !important;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 992px) {
    .propiedad {
        .main {
            height: 90vh;
            background-attachment: unset;
            position: relative;
            min-height: 90vh;
            div a::before {
                width: 8rem;
                height: 7rem;
            }
            &::before {
                opacity: 1;
            }
            &::after {
                height: 0.5rem;
            }
            .content-main {
                height: 90vh;
                h2 {
                    font-size: 2.6rem;
                    font-weight: 300;
                    display: block;
                    line-height: 1;
                    margin-bottom: 0 !important;
                    .location {
                        font-size: 1rem;
                    }
                }
                .btn {
                    width: 4rem;
                    height: 4rem;
                    font-size: 1rem;
                }
            }
            .btn {
                width: 5rem;
                height: 5rem;
                // position: absolute !important;
                left: calc(0%);
                bottom: 0vh;
                font-size: 1rem;
            }
        }
    }
}