.emprendimientos {
  .main {
    height: 100vh;
    overflow: hidden;
    position: relative;
    .content-image {
      position: relative;
      height: 85vh;
      transition: 0.4s ease all;
      overflow: hidden;
      img {
        height: 100%;
        object-fit: cover;
        object-position: center;
        width: 100%;
      }
      &::before {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        content: "";
        // background-color: #0f0f0f;
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0.751278182171306) 0%,
          rgba(115, 115, 115, 0) 40%,
          rgba(255, 255, 255, 0) 100%
        );
        opacity: 1;
        z-index: 9;
        display: block;
      }
    }
    .content-slider {
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 9;
      left: 5%;
      width: max-content;
      height: 15vh;
      .item_slider {
        h3 {
          height: 3.5rem;
          line-height: 1.5 !important;
          width: 25rem;
          span {
            &:nth-child(1) {
              color: $bg-quarter;
              z-index: 1;
              margin-right: 2rem;
              font-size: 2.3rem;
              height: 3.5rem;
              &::before {
                content: "";
                position: absolute;
                background: $bg-secondary;
                height: 3.5rem;
                width: 4.4rem;
                z-index: -1;
                transform: skew(345deg);
                left: -1rem;
              }
            }
            &:nth-child(2) {
              font-size: 2rem;
              &::before {
                content: "";
                position: absolute;
                border: 1px solid $bg-secondary;
                height: 3.5rem;
                width: 4.4rem;
                z-index: -1;
                transform: skew(345deg);
                left: 0rem;
                width: 25rem;
              }
            }
          }
        }
      }
      .item {
        position: relative;
        margin-right: 7vw;
        z-index: 9;

        .arrows {
          display: none;
          cursor: pointer;
          .next,
          .back {
            &:hover {
              // border: 2px solid $secondary-color;
              background-color: #fff !important;
              // color: black !important;
              i {
                color: $bg-secondary;
              }
            }
          }
        }
        h5,
        a {
          display: none;
        }
        &.active {
          padding: 2.35rem 3rem;
          width: 27rem;
          height: 24rem;
          // background: $tertiary-color;
          color: #fff;
          margin-bottom: 13.13rem;
          display: inline-block;

          &::before {
            content: "";
            position: absolute;
            width: 25rem;
            height: 40rem;
            background: transparent
              linear-gradient(261deg, #ed362d 0%, #cc3730 47%, #951712 100%) 0%
              0% no-repeat padding-box;
            z-index: -1;
            top: -1rem;
            left: -1rem;
          }
          &::after {
            content: "";
            position: absolute;
            width: 6rem;
            height: 40rem;
            background: #e43a31;
            z-index: -2;
            top: -1rem;
            left: 19rem;
            transform: skew(348deg);
          }
          h3 {
            font-size: 3.75rem;
            display: block;
            font-weight: 400;
            margin-bottom: 1.8rem;
          }
          h5 {
            font-size: 1.4rem;
            display: block;
            font-weight: 300;
          }
          a {
            margin-top: 5rem;
            display: block;
            font-size: 1rem;
            font-weight: 300;
            color: #fff;
            text-decoration: none;
            &:hover {
              text-decoration: underline;
            }
          }
          .arrows {
            display: block;
            opacity: 1;
            .next {
              width: 3.5rem;
              background-color: $secondary-color;
              color: #fff;
              height: 3.5rem;
              font-size: 1.2rem;
              position: absolute;
              right: -1.5rem;
              top: calc(50% - 1.5rem);
            }
            .back {
              width: 3.5rem;
              background-color: $secondary-color;
              color: #fff;
              transform: rotate(180deg);
              height: 3.5rem;
              font-size: 1.2rem;
              position: absolute;
              left: -1.5rem;
              top: calc(50% - 1.5rem);
            }
          }
        }
      }
    }
  }
}

@media (min-width: 992px) {
  .emprendimientos {
    .full-container {
      max-width: 95%;
      margin-left: auto;
    }
  }
}

@media (max-width: 992px) {
  .emprendimientos {
    .main {
      .content-slider {
        .item {
          // margin-left: 1rem;
          h5,
          a {
            display: none;
          }
          &.active {
            padding: 1.35rem 2rem;
            width: 20rem;
            height: 17rem;
            // margin-bottom: 20rem;
            h3 {
              font-size: 2rem;
              margin-bottom: 1.5rem;
            }
            h5 {
              font-size: 1rem;
            }
            a {
              margin-top: 4rem;
              font-size: 0.8rem;
            }
            .arrows {
              display: block;
              opacity: 1;
              .next {
                width: 2.5rem;
                background-color: $secondary-color;
                color: #fff;
                height: 2.5rem;
                font-size: 1rem;
              }
              .back {
                width: 2.5rem;
                background-color: $secondary-color;
                color: #fff;
                height: 2.5rem;
                font-size: 1rem;
              }
            }
          }
        }
      }
    }
  }
}
