.opacity {
    opacity: 0;
}
.pagination {
    
    .pagination-item {
        width: 2rem;
        height: 2rem;
        background-color: #303030;
        line-height: 1.2;
        padding-bottom: 3px;
        font-size: 1rem;
        border-radius: 50%;
        color: #fff;
        margin: 0 1rem;
        transition: .2s ease-in all;
        border: 1px solid gray;
        &.opacity-disabled {
            opacity: .2;
            cursor: not-allowed;
            &:hover {
                background-color: #303030;
                color: #fff;
            }
        }
        &:hover {
            background-color: transparent;
            border: 1px solid gray;
            color: $secondary-color;
        }
    }
    .container-pagination {
        border: 1px solid #303030;
        padding: .2rem .1rem;
        border-radius: 15px;
        .item-paginate {
            color: rgb(143, 143, 143);
            background-color: transparent;
            cursor: pointer;
            padding: .5rem 1rem;
            border-radius: 15px;
            font-size: .8rem;
            font-weight: 600;
            min-width: 5rem;
            text-align: center;
            // transition: .1s ease-in all;
            &.active {
                color: #fff;
                background-color: #ED362D;
            }
        }
    }
    h5 {
        font-size: .65rem;
        strong {
            font-size: .9rem;
            font-weight: 400;
        }
    }
}