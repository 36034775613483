* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  overflow-x: hidden;
  font-size: 16px;
}

body {
  font-size: 16px;
  font-family: "FR Hopper 430";
  font-weight: 100;
  overflow-x: hidden;
}

.section_indicator {
  border-bottom: 2px solid $secondary-color;
  padding-bottom: 0.9rem;
  margin-bottom: 4rem;
  span {
    width: 100%;
    font-size: 0.68rem;
    font-weight: 300;
  }
}

input:focus,
select:focus,
textarea:focus {
  outline: none;
}

p {
  font-weight: 100;
}

.social-media {
  margin-top: 2rem;
  margin-bottom: 1.5rem;
  .single {
    width: 3.2rem;
    height: 3.2rem;
    display: flex;
    transition: 0.4s ease all;
    align-items: center;
    justify-content: center;
    // background-color: $secondary-color;
    cursor: pointer;
    margin-right: 2rem;
    border-bottom: none !important;
    text-decoration: none;
    .small-single {
      width: 0.9rem;
    }
    img {
      width: 1.5rem;
    }
    i {
      font-size: 1.4rem;
      color: $primary-color;
    }
    &:hover {
      i {
        color: $primary-color;
      }
    }
  }
}

.social-block {
  // overflow: hidden;
  position: relative;
  transition: all 0.3s ease;
  z-index: 1;
  &.hidden {
    transition: all 0.3s ease;
    // -webkit-transform: translateX(1000px);
    // transform: translateX(1000px);
    // right: 0;
    opacity: 0;
    z-index: -1;
    // position: absolute;
  }
  .btn {
    width: 2.5rem !important;
    z-index: -1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.5rem !important;
    color: #fff !important;
    &:hover {
      color: $primary-color;
      background-color: $secondary-color;
    }
    i {
      margin: 0 !important;
    }
    &:hover {
      border: none;
    }
  }
}

.breadcrumb-item {
  font-size: 1rem;
  margin-bottom: 1.5rem;
  a {
    font-size: 1rem;
    font-weight: 430;
    color: $bg-terciary;
    text-transform: uppercase;
    text-decoration: none;
  }
}

.breadcrumb-item + .breadcrumb-item {
  padding: 0rem;
  font-size: 1rem;
  color: $bg-primary !important;
  text-transform: uppercase;
  &::before {
    content: "|";
    padding: 0 0.5rem;
    color: $bg-terciary;
  }
  a {
    padding-left: 0rem;
    font-size: 1rem;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.see_description {
  color: $primary-color;
  font-size: 1.4rem;
  font-weight: 500;
  text-decoration: none;
  &:hover {
    text-decoration: none;
    color: $primary-color;
  }
}

.fixed-bar,
.scroll-bar {
  bottom: 20vh;
  right: 1.6%;
  z-index: 9999;
  opacity: 1;
  display: flex;
  transition: 0.4s ease all;
  .btn {
    padding: 0;
    position: relative;
    z-index: 99;
    width: 5.5rem;
    height: 5.5rem;
    font-size: 1.5rem;
    background: none !important;
    border: none !important;
    margin-left: 1rem;
    transition: 0.2s ease all;
    color: $bg-primary;
    &.go-top {
      width: 0;
      height: 0;
      overflow: hidden;
      border: none;
      background: transparent;
      border: none;
      &.active {
        height: 5.5rem;
        // border: 2px solid #fff;
        width: 5.5rem;
      }
    }
    span {
      transform: rotate(-90deg);
      // background: $tertiary-color;
      color: $bg-primary;
      font-size: 5rem;
    }
    &:hover {
      background: $tertiary-color;
      border: 2px solid $primary-color;
      // span {
      //   // color: #fff !important;
      // }
    }
    &.disabled {
      opacity: 0.3 !important;
      cursor: not-allowed !important;
      pointer-events: unset !important;
    }
  }
  &.opacity-total {
    opacity: 0;
  }
  .contact-btn {
    width: 5rem;
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: #00bb2d;
    color: #fff;
    font-size: 2.5rem;
    position: relative;
    text-decoration: none;
    box-shadow: 0px 0px 5px $bg-secondary;
    &:after {
      content: "";
      width: 100%;
      height: 100%;
      // background: #fff;
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      transform: scale(0);
      will-change: transform;
      transition: 0.4s;
      border-radius: 50%;
    }
    &:hover:after {
      transform: scale(1);
      opacity: 1;
    }
    &:hover:before {
      // color: $bg-primary;
    }
    &:before {
      z-index: 9;
      transition: 0.3s;
      transition-delay: 0.1s;
    }
  }
}

.btn {
  display: flex;
  background: $bg-primary;
  color: #ffffff;
  font-size: 1.1rem;
  letter-spacing: -0.1px;
  // border: 2px solid $primary-color;
  line-height: -9;
  border-radius: 0;
  justify-content: space-between;
  padding: 0.5rem;
  &:hover {
    // background: #000;
    border: 2px solid #fff;
    color: #fff;
  }
  &.transparent {
    background: transparent;
  }
  &.btn-effect {
    width: auto;
    border-radius: 0;
    padding: 1.1rem 2.5rem;
    padding-right: calc(2.5rem + 3.5rem);
    color: $primary-color;
    position: relative;
    overflow: hidden;
    &::before {
      content: "+";
      line-height: 1;
      display: flex;
      font-size: 2rem;
      padding-bottom: 3px;
      font-weight: 400;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: -1px;
      width: 4rem;
      top: 0;
      z-index: 1;
      bottom: -1px;
      text-transform: uppercase;
      background: $tertiary-color;
      transition: width 0s ease;
      color: #fff;
    }
    &::after {
      content: "";
      line-height: 1;
      display: flex;
      font-size: 2rem;
      padding-bottom: 3px;
      font-weight: 400;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: -1px;
      width: 4rem;
      top: 0;
      bottom: -1px;
      text-transform: uppercase;
      background: $tertiary-color;
      color: #fff;
      transition: width 0.4s ease;
    }
    &:hover {
      z-index: 5;
      border-color: $primary-color;
      position: relative;
      color: #fff;
      &::after {
        content: "";
        width: 101%;
        right: -1px;
        z-index: -1;
        font-size: 1rem;
        transform: scale(1);
      }
      &::before {
        background: transparent;
      }
    }
  }
}

.button_more_propiedades {
  padding: 0 !important;
  height: 100px;
  position: relative;
  width: 220px !important;
  left: 50px;
  background: white;
  &:focus {
    outline: none;
    box-shadow: none;
    border: none;
  }
  &:hover {
    &::after {
      content: "";
      z-index: 0;
      background: $bg-primary;
      height: 100px;
      width: 100%;
      display: block;
      position: absolute;
      // left: 0%;
      transform: skew(343deg);
      -webkit-transition: all 0.35s;
      transition: all 0.35s;
    }
    .text_button_more_propiedades {
      color: white;
      font-family: "Lato-Regular" !important;
    }
    .open_more_propiedades {
      span {
        -webkit-transition: all 0.35s;
        transition: all 0.35s;
        &:nth-child(1) {
          transform: rotate(180deg) translate(0px, 0px);
          // left: 10px;
        }
        &:nth-child(2) {
          transform: rotate(440deg) translate(0px, 0px);
        }
      }
    }
  }
  &::after {
    content: "";
    z-index: 1;
    background: $bg-primary;
    height: 100px;
    width: 0%;
    display: block;
    position: absolute;
    left: 0%;
    transform: skew(343deg);
    -webkit-transition: all 0.35s;
    transition: all 0.35s;
  }
  .open_more_propiedades {
    z-index: 2;
    position: relative;
    height: 100px;
    width: 100px;
    left: -100px;
    background: $bg-primary;
    // top: -1%;
    transform: skew(343deg);
    span {
      position: absolute;
      width: 1.5rem;
      height: 0.2rem;
      background-color: #ffffff !important;
      display: block;
      z-index: 4;
      left: 39%;
      top: 49%;
      -webkit-transition: all 0.35s;
      transition: all 0.35s;
      &:nth-child(1) {
        transform: rotate(75deg) translate(0px, 0px);
      }
      &:nth-child(2) {
        transform: rotate(360deg) translate(0px, 0px);
        background: #ffffff !important;
      }
    }
  }
  .text_button_more_propiedades {
    font-size: 1.2rem;
    font-family: "FR Hopper 430";
    color: $bg-secondary;
    position: absolute;
    z-index: 3;
    top: 35%;
    left: 10%;
    // margin-left: 10px !important;
    // margin-right: 10px !important;
  }
  &::before {
    content: "";
    z-index: 1;
    height: 100px;
    border: 1px solid $bg-primary;
    width: 101%;
    top: 0;
    left: -1%;
    display: block;
    position: absolute;
    transform: skew(343deg);
    -webkit-transition: all 0.35s;
    transition: all 0.35s;
  }
}

.map {
  height: 60vh;
}

.not-results-message {
  font-size: 1.35rem;
  color: #8c96a4;
  font-weight: 400;
}

@media (max-width: 992px) {
  .section_indicator {
    border-bottom: 1px solid $secondary-color;
    padding-bottom: 5px;
    margin-bottom: 2rem;
    span {
      font-size: 0.4rem;
    }
  }
  .overflow-hidden-mobile {
    overflow: hidden;
  }
  .social-media {
    width: 100%;
    text-decoration: none;
    .single {
      width: 1.6rem;
      margin-right: 0.5rem;
      height: 1.6rem;
      .small-single {
        width: 0.6rem;
      }
      img {
        width: 0.8rem;
      }
    }
  }
  .btn {
    font-size: 1rem;
    &.btn-effect {
      padding: 0.9rem 2.2rem;
      padding-right: calc(2.2rem + 3.5rem);
    }
    .open_more_propiedades {
      padding: 1.5rem;
    }
  }
  // Breadcrumbs tasaciones
  .text-white-mobile {
    color: #fff !important;
    a {
      color: #fff !important;
    }
  }
  .before-white-mobile {
    &::before {
      color: #fff !important;
    }
  }
  .breadcrumb-item {
    font-size: 0.9rem;
    a {
      font-size: 0.9rem;
    }
  }
  .breadcrumb-item + .breadcrumb-item {
    font-size: 0.9rem;
    &::before {
      padding: 0 0.3rem;
    }
    a {
      font-size: 0.9rem;
    }
  }
  .fixed-bar,
  .scroll-bar {
    bottom: 50vh;
    right: 2rem;
    z-index: 9999;
    transition: 0.4s ease-in-out all;
    .contact-btn {
      width: 4rem;
      height: 4rem;
      font-size: 1.5rem;
    }
    .btn.go-top.active {
      width: 4rem;
      height: 4rem;
    }
    &.menu-open {
      display: none;
    }
  }
  .scroll-bar {
    bottom: 5vh;
  }
  .map {
    height: 55vh;
    position: relative;
  }
}

@media (min-width: 992px) {
  .container-fluid {
    max-width: 90%;
  }
}

@media (min-width: 992px) and (max-width: 1400px) {
  html {
    font-size: 11px;
  }
}

@media (min-width: 1400px) and (max-width: 1700px) {
  html {
    font-size: 13px;
  }
}

@media (min-width: 1500px) and (max-width: 1920px) {
  html {
    font-size: 14px;
  }
}

@media (min-width: 1920px) {
  html {
    font-size: 16px;
  }
}
