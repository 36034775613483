.tasaciones,
.contacto {
  .main {
    overflow: hidden;
    .full-fluid {
      min-height: 100vh;
    }
    .btn {
      background: transparent;
      position: relative;
      overflow: visible;
      left: -30px;
      line-height: 1;
      bottom: 15%;
      margin-left: 75px;
      width: max-content !important;
      &:focus {
        outline: none;
        box-shadow: none;
        border: none;
      }
      &:hover {
        border: none !important;
        color: $primary-color;
        transform: none !important;
        transform: skew(40deg);
        color: #ffffff !important;
        &::after {
          content: "";
          height: 3.5rem;
          width: 80%;
          top: 0;
          background: #ed362d;
          // z-index: 2 !important;
          border: 1px solid #ed362d;
          left: 22px;
          position: absolute;
          transform: skew(340deg);
        }
        &::before {
          transition: 0.5s ease all;
          left: 87%;
        }
        .btn-effect {
          color: white !important;
          z-index: 9;
        }
        span {
          left: 86% !important;
        }
      }
      &::before {
        font-family: "icomoon" !important;
        speak: never;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        /* Better Font Rendering =========== */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: "\e904";
        font-size: 1.5rem;
        background: none;
        left: -9%;
        top: 0 !important;
        height: 3.5rem !important;
        z-index: 3 !important;
      }
      &::after {
        content: "";
        height: 3.5rem;
        width: 80%;
        top: 0;
        background: transparent;
        // z-index: 3 !important;
        border: 1px solid #ed362d;
        left: 22px;
        position: absolute;
        transform: skew(340deg);
      }
      span {
        content: "";
        height: 3.5rem;
        width: 60px;
        top: 0;
        background: #ed362d;
        z-index: 2 !important;
        left: -11%;
        position: absolute;
        transform: skew(340deg);
        transition: 0.5s ease all;
      }
    }
    .btn-effect {
      border: none !important;
      z-index: 99999999999;
      padding: 1.1rem 4rem;
      &:hover {
        z-index: 999999999999999999999999;
        border: none;
        // position: absolute;
        color: white !important;
        transform: skew(0deg) !important;
        &::after {
          content: "";
          width: none !important;
          right: none !important;
          //   z-index: 4;
          font-size: none !important;
          //   transform: none !important;
        }
        &::before {
          background: none !important;
        }
      }
    }
    .primary-row {
      min-height: 100vh;
      .left-column {
        position: relative;
        z-index: 2;
        padding-left: 0%;
        margin-top: 3rem;
        .container_left_column {
          width: 90%;
          margin-top: 25vh !important;
          position: relative;
          span {
            // font-family: "FR Hopper 430";
            // margin-bottom: 2rem;
            // font-weight: 400;
            // display: block;
            // font-size: 1.3rem;
            // letter-spacing: 3px;
            color: $bg-primary;
            // margin-top: 2rem;
            // color: #fff;
          }
          h2 {
            color: #303030;
            font-size: 3.5rem;
            line-height: 1.2;
            font-weight: 200;
            margin-bottom: 5rem;
            transition: 0.3s ease all;
            text-decoration: none;
          }
          &.step-2-column {
            padding-top: 25vh;
          }
        }
        // .btn_form_tasacion {
        //   position: absolute;
        //   bottom: 8%;
        // }
      }
      .img {
        filter: brightness(0.6);
        z-index: 3;
        position: relative;
        z-index: 2;
        opacity: 0.8;
        .img_and_lateral {
          position: relative;
          &::before {
            content: "";
            top: 0;
            right: 0;
            width: 100%;
            position: absolute;
            background-position: center;
            background-size: cover;
            right: 0px;
            z-index: 2;
            background-image: url("../../images/portada-4.png");
            height: 100vh;
          }
        }
      }
      .formulario {
        margin-top: 12rem;
        .div_limpiar {
          span {
            font-size: 1.2rem !important;
          }
        }
        span {
          text-transform: uppercase;
          color: $bg-primary;
          margin-bottom: 2rem;
          font-size: 1.8rem;
        }
        h2 {
          font-size: 5rem;
        }
      }
    }
    .tick {
      background-color: $primary-color;
      width: 8rem;
      border-radius: 100%;
      margin-bottom: 2rem;
      height: 8rem;
      i {
        color: #fff;
        font-size: 2rem;
      }
    }
    &.step-2 {
      background-image: url("../../images/contacto-2.jpg");
      background-position: center;
      background-size: cover;
      background-attachment: fixed;
      background-color: #fff;
      background-repeat: no-repeat;
      &::before {
        content: "";
        background-color: #000;
        opacity: 0.2;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
      }
      .full-fluid {
        .row {
          padding-top: 12vh;
          h2 {
            color: #fff !important;
            font-weight: 400;
          }
          .text-thanks {
            font-size: 3.5rem;
            font-weight: 300;
          }
        }
      }
    }
    .content-form {
      width: 90%;
      margin: auto;
      padding-bottom: 10vh;
      input[type="text"],
      input[type="tel"],
      input[type="email"],
      input[type="name"],
      select,
      textarea {
        width: 100%;
        padding: 1.4rem 2rem;
        font-size: 1rem;
        background-color: transparent;
        border: 1px solid $secondary-color;
        &::placeholder {
          color: $secondary-color;
        }
        &:focus {
          border: 1px solid $secondary-color !important;
          outline: none;
        }
      }
      select {
        color: $secondary-color;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        position: relative;
        background-color: transparent;
        z-index: 1;
      }
      i {
        position: absolute;
        z-index: -1;
        right: 2rem;
        top: calc(50% - 0.6rem);
        width: 0.75rem;
        height: 0.75rem;
        display: block;
        border-left: 1px solid $secondary-color;
        border-bottom: 1px solid $secondary-color;
        transform: rotate(-45deg);
        /* Giramos el cuadrado */
        transition: all 0.25s ease;
      }
      .border-bottom-name {
        background-color: #1d1d1d;
        width: 100%;
        height: 1px;
        margin: 3rem 0 4rem 0;
      }
      label {
        color: $secondary-color;
        margin-bottom: 2rem;
        font-weight: 500;
        font-size: 1.2rem;
        margin-top: 2.5rem;
        letter-spacing: 0;
      }
      .option {
        display: inline-block;
        input {
          display: none;
          &:checked + label {
            span {
              background: rgba($color: $primary-color, $alpha: 1);
              color: #fff;
              border: 0;
              &:before {
                background: $primary-color;
                transform: translate(-50%, -50%) scale(1.15);
              }
            }
          }
        }
        label {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          color: $secondary-color;
          font-weight: 300;
          margin-top: 0;
          span {
            display: flex;
            align-items: center;
            color: #fff;
            justify-content: center;
            vertical-align: middle;
            position: relative;
            width: 3rem;
            height: 3rem;
            border: 1px solid transparent;
            background: $bg-primary;
            border-radius: 50%;
            margin-bottom: 0;
            &:before {
              content: "";
              display: inline-block;
              transition: 0.3s;
              position: absolute;
              color: #fff;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%) scale(0);
            }
          }
          .square {
            border-radius: 0;
            &::before {
              border-radius: 0;
            }
          }
        }
      }
      .option-check {
        margin-bottom: 0.5rem !important;
        input {
          display: none;
          &:checked + label {
            span {
              background: rgba($color: $primary-color, $alpha: 0.5);
              color: #fff;
              border: 0;
              &:before {
                background: $primary-color;
                transform: translate(-50%, -50%) scale(1.15);
              }
            }
          }
        }
        label {
          margin-right: 6rem;
          span {
            width: 22px;
            height: 22px;
            border: 1px solid rgba($color: $secondary-color, $alpha: 0.5);
            background: transparent;
            border-radius: 50%;
            margin-right: 0.5rem;
            &:before {
              content: "";
              width: 9px;
              height: 9px;
              transition: 0.3s;
              border-radius: 50%;
            }
          }
        }
      }
      .btn {
        line-height: 3;
        width: 6rem !important;
        &:hover {
          .text_btn_form {
            color: $bg-quarter !important;
            z-index: 3;
            &::after {
              background: $bg-primary !important;
              z-index: -1;
            }
          }
        }
        &::before {
          /* use !important to prevent issues with browser extensions that change fonts */
          font-family: "icomoon" !important;
          speak: never;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          text-transform: none;
          line-height: 1;
          /* Better Font Rendering =========== */
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          content: "\e90b";
          font-size: 1rem;
          z-index: 0 !important;
          top: 0;
        }
        &::after {
          transform: skew(0);
          border: none;
        }
        .text_btn_form {
          position: absolute;
          left: 2rem !important;
          padding: 0 !important;
          color: $bg-primary !important;
          &::after {
            content: "";
            top: 0;
            left: -2rem;
            width: 9rem;
            height: 3.5rem;
            position: absolute;
            border: 1px solid $bg-primary;
            transform: skew(340deg);
          }
        }
      }
    }
  }
}

@media (min-width: 1920px) {
  .tasaciones {
    .main {
      .btn {
        line-height: 1;
        width: max-content !important;
        span {
          left: -10% !important;
        }
      }
      // .content-form {
      //     .btn-effect {
      //         height: 3.5rem;
      //     }
      //     p {
      //         top: 0;
      //     }
      // }
    }
  }
}

@media (max-width: 1400px) {
  .tasaciones {
    .main {
      .btn {
        line-height: 1;
        span {
          left: -10% !important;
        }
      }
      // .content-form {
      //     #btn-state {
      //         &::before {
      //             height: 3.5rem !important;
      //             top: 0 !important;
      //         }
      //     }
      //     p {
      //         top: 0;
      //     }
      // }
    }
  }
}

@media (min-width: 992px) {
  .tasaciones,
  .contacto {
    .main {
      .full-fluid {
        max-width: 95%;
        margin-left: auto;
        padding-right: 0;
        margin-right: 0;
        position: relative;
      }
    }
  }
}

@media (max-width: 992px) {
  .tasaciones {
    width: 100vw;
    overflow: hidden;
    .main {
      min-height: 90vh;
      overflow: hidden;
      width: 100%;
      .full-fluid {
        min-height: 90vh;
        margin: auto;
        width: 90vw;
      }
      .primary-row {
        min-height: 90vh;
        &::before {
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          right: 0;
          background-color: #303030;
          opacity: 0.6;
          z-index: 8;
          content: "";
        }
        &.bg-transparent {
          &::before {
            background-color: transparent;
          }
        }
        .formulario {
          margin-top: 3rem;
          h2 {
            font-size: 2.5rem;
          }
        }
        .left-column {
          position: relative;
          z-index: 10;
          padding-top: 4rem;
          span {
            margin-bottom: 1rem;
            font-size: 1rem;
            text-transform: uppercase;
            letter-spacing: 2px;
            // color: #fff;
          }
          h2 {
            margin-bottom: 1.8rem;
            position: initial !important;
            color: #fff !important;
            font-size: 2rem !important;
          }
          &.step-2-column {
            padding-top: 5vh;
            z-index: 99;
            background-color: #fff;
            span,
            h2 {
              color: #1d1d1d;
            }
          }
          .container_left_column {
            margin-top: 10vh !important;
          }
          a {
            // bottom: 50px !important;
          }
        }
        .img {
          height: 100vh;
          position: absolute !important;
          top: 0;
          left: 0;
          right: 0;
          width: 100% !important;
          overflow: hidden;
          .img_and_lateral {
            // position: absolute !important;
            z-index: 1;
            &::before {
              right: -10px;
              left: -10px;
              width: 110%;
              background-image: url("../../images/img_tasaciones.jpg");
            }
          }
        }
      }
      .content-form {
        padding: 0;
        width: 100%;
        z-index: 99;
        position: relative;
        input[type="text"],
        input[type="tel"],
        input[type="email"],
        select,
        textarea {
          padding: 0.8rem 1rem;
          font-size: 0.8rem;
          margin-bottom: 0.8rem;
        }
        .option-check {
          label {
            margin-right: 0;
          }
        }
        label {
          margin-bottom: 1.5rem;
          font-size: 0.95rem;
        }
        i {
          top: calc(50% - 1rem);
        }
        .btn {
          margin-bottom: 10vh;
          margin-top: 3vh !important;
        }
      }
      .tick {
        width: 5rem;
        margin-bottom: 2rem;
        height: 5rem;
        i {
          color: #fff;
          font-size: 1.5rem;
        }
      }
      &.step-2 {
        max-height: 100vh;
        .full-fluid {
          .row {
            background: transparent !important;
            padding-top: 0vh;
            margin-bottom: 20vh;
            h2 {
            }
            .text-thanks {
              font-size: 2rem;
              font-weight: 300;
            }
          }
        }
      }
      .btn {
        display: block;
        margin-top: 3vh;
        padding-top: 1rem;
        padding-bottom: 0.8rem;
        line-height: 1;
        font-size: 0.9rem;
        &.btn-movil-white {
          color: $bg-primary;
          font-size: 0.75rem;
          padding-left: 3rem;
          padding-right: 3rem;
          padding: 1.15rem 3rem 1.15rem 3rem;
          position: absolute;
          bottom: 10vh;
          &::after {
            z-index: -1;
            width: 80%;
            background: #fff;
          }
          &::before {
            top: 0 !important;
            font-size: 1.1rem;
            height: 3.3rem !important;
          }
          &:hover {
            color: $bg-primary !important;
          }
        }
      }
    }
    #formContact div div .btn-effect {
      margin-left: 7rem;
      margin-bottom: 5rem;
    }
  }
}

@media (max-width: 500px) {
}
