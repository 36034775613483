.grid_item {
  position: relative;
  // height: 30vh;
  &:hover {
    .hover {
      bottom: 0;
    }
    img {
      //   object-position: bottom;
      transform: scale(1.3);
    }
  }
  img {
    width: 100%;
    height: 100%;
    transition: 2s ease-in-out all;
    object-fit: cover;
    border: none;
    transform: scale(1);
    object-position: center;
  }
  .hover {
    position: absolute;
    bottom: -100%;
    transition: 0.3s ease all;
    left: 0;
    right: 0;
    background-color: #efefef;
    width: 100%;
    z-index: 99;
    height: fit-content;
    border: none;
    padding: 2rem 0;
    h5,
    p {
      margin-bottom: 0;
    }
    h5 {
      font-size: 1.56rem;
      text-transform: uppercase;
      font-weight: 400;
    }
    p {
      font-size: 1.4rem;
      font-weight: 300;
    }
    a {
      width: 3.5rem;
      text-decoration: none;
      font-size: 1.5rem;
      height: 3.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      vertical-align: middle;
      transition: 0.3s ease-in-out all;
      background: $tertiary-color;
      border: 2px solid $primary-color;
      color: #fff;
      &:hover {
        background: transparent;
        border: 2px solid $primary-color;
        color: $primary-color;
      }
    }
  }
}

@media (min-width: 992px) {
  .grid_item {
    grid-column: span 1;
    overflow: hidden !important;
    border: none;
    transition: 0.3s ease-in-out all;
    grid-row: span 1;
  }
  &.alt-1 {
    grid-row: span 4;
  }
  &.alt-2 {
    grid-row: span 6;
  }
  &.alt-3 {
    grid-row: span 5;
  }
  &.alt-4 {
    grid-row: span 8;
  }
  &.alt-5 {
    grid-row: span 5;
  }
}

@media (max-width: 992px) {
  .grid_item {
    height: 20vh;
    margin-bottom: 1rem;
    .hover {
      background-color: rgba(#fff, 0.5);
      bottom: 0;
      top: 0;
      height: 100%;
      padding: 1rem 0.5rem;
      p {
        margin-bottom: 0 !important;
      }
    }
    img {
      height: 100%;
      transform: scale(1);
    }
    &:nth-child(1) {
      height: unset;
    }
  }
}
