.emprendimientos {
  .investors {
    padding-top: 15vh;
    padding-bottom: 15vh;
    background-color: #efefef;
    .container-fluid {
      h4 {
        text-align: center;
        font-size: 2.4rem;
        text-transform: uppercase;
        padding-bottom: 10vh;
      }
      .div_item_investors {
        div {
          .item {
            height: auto;
            //   overflow: hidden;
            transition: 0.4s ease all;
            padding: 1.5rem;
            position: relative;

            h5 {
              text-transform: uppercase;
              min-height: 8.5rem;
              transition: 0.4s ease all;
              color: $bg-quarter;
            }
            h5,
            p {
              font-size: 1.5rem;
              width: 80%;
              margin: auto;
              line-height: 1.2;
              transition: 0.4s ease all;
              color: $bg-quarter;
            }
            img {
              transition: all 0.4s ease;
              position: relative;
              top: 0;
              color: black;
              padding: 1.5rem;
            }
            .btn {
              transition: 0.2s ease all;
              border: 2px solid;
              padding: 2rem 6rem;
              display: inline-block;
              text-decoration: none;
              margin-top: 2rem;
              transform: translateY(1000px);
            }
            &:hover {
              transform: translateY(-4rem);
              .btn {
                transform: translateY(0);
              }
              img {
                // position: absolute;
                // top: -500px;
                opacity: 0;
              }
              h5 {
                color: $primary-color;
              }
            }
            &::before {
              content: "";
              position: absolute;
              height: 473px;
              width: 31rem;
              background: #646464;
              left: 2rem;
              top: 0;
              box-shadow: 0px 0px 15px #00000033;
              transform: skew(353deg);
            }
          }
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .emprendimientos {
    .investors {
      padding-top: 10vh;
      padding-bottom: 5vh;
      h4 {
        font-size: 2rem;
        padding-bottom: 10vh;
      }
      .item {
        h5 {
          min-height: 6.5rem;
        }
        h5,
        p {
          font-size: 1.3rem;
          width: 85%;
          line-height: 1.25;
        }
        .btn {
          padding: 0.6rem 6rem;
          margin: 1.5rem 0 5rem 0;
          transform: translateY(0px);
        }
        &:hover {
          transform: translateY(0rem);
          img {
            opacity: 1;
          }
        }
      }
    }
  }
}
